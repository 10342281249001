<template>
  <div class="item-wrap">
    <div class="list-item" @click="handleToDetail">
      <div class="tag-wrap">
        <div class="tag tag1" v-if="false">正式</div>
        <div class="tag tag2" v-else>候选</div>
      </div>
      <div :class="{ invalid: subTabActive === 2 }">
        <div class="title">MEB TEAM</div>
        <div class="content">
          <div class="ticket">
            <div class="ticket">理事的票数</div>
            <div class="percent" :class="{ invalid: subTabActive === 2 }">
              231,123,412
            </div>
          </div>
          <div class="ticket">
            <div class="ticket">投票人数</div>
            <div class="percent" :class="{ invalid: subTabActive === 2 }">
              231,123,412
            </div>
          </div>
          <div
            class="operation"
            :class="{ 'invalid-button': subTabActive === 2 }"
          >
            <div class="operation-button" @click.stop="handleShowRedeem">
              赎回
            </div>
          </div>
        </div>
        <div class="ticket-already" :class="{ invalid: subTabActive === 2 }">
          <div class="ticket">已投票</div>
          <div class="percent" :class="{ invalid: subTabActive === 2 }">
            231,123,412
          </div>
        </div>
        <div
          class="stake-button"
          @click.stop="handleShowStake"
          v-if="mainTabActive === 2 && subTabActive === 1"
        >
          质押
        </div>
        <div class="detail">
          <div class="info-title">竞选人信息:</div>
          <div class="oneline">
            <div class="time">
              <div class="title">创建时间</div>
              <div class="value" :class="{ invalid: subTabActive === 2 }">
                2022-03-08 12:12:11
              </div>
            </div>
            <div class="stake-num">
              <div class="title">质押凭证数</div>
              <div class="value" :class="{ invalid: subTabActive === 2 }">
                213,123,431
              </div>
            </div>
          </div>
          <div class="twoline">
            <div class="time">
              <div class="title">创建时间</div>
              <div class="value" :class="{ invalid: subTabActive === 2 }">
                2022-03-08 12:12:11
              </div>
            </div>
          </div>
          <div class="twoline">
            <div class="time">
              <div class="title">竞选说明</div>
              <div class="value" :class="{ invalid: subTabActive === 2 }">
                2022-03-08 12:12:11
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  name: 'VoteItem',
  props: {
    subTabActive: {
      type: Number,
      default: 1
    },
    mainTabActive: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleToDetail () {
      this.detailIsShow = !this.detailIsShow
    },
    handleShowRedeem () {
      if (this.subTabActive === 2) return
      this.$emit('showRedeem')
    },
    handleShowStake () {
      this.$emit('showStake')
    }
  },
  mounted () {

  },
  watch: {
    subTabActive (val) {
      console.log(val)
    }
  }
}
</script>

<style scoped lang="scss">
.invalid {
  color: #c1c1c1 !important;
}
.invalid-button {
  color: #c1c1c1 !important;
  border: 1px solid #c1c1c1 !important;
}
.item-wrap {
  margin-top: 10px;
}
.list-item {
  border-top: 1px solid #e3e3e3;
  padding-bottom: 15px;
  .tag-wrap {
    display: flex;
    justify-content: flex-end;
    .tag {
      width: 35px;
      height: 18px;
      color: #fff;
      border-radius: 0px 0px 5px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 18px;
    }
    .tag1 {
      background: #000;
    }
    .tag2 {
      background-color: #a2a2a2;
    }
  }
}
.stake-button {
  font-size: 15px;
  // margin: 10px 0;
  color: #fff;
  font-weight: bold;
  line-height: 40px;
  height: 40px;
  background: #000;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding-top: 10px;
}
.content {
  margin-top: 20px;
  display: flex;
  flex-basis: 6rem;
  justify-content: space-between;
  // align-items: center;
  font-size: 12px;
  .percent {
    color: #818086;
  }
  .operation {
    width: 66px;
    height: 30px;
    background: #fff;
    border: 1px solid #000;
    text-align: center;
    line-height: 30px;
    color: #000;
    border-radius: 10px;
    font-weight: bold;
  }
}
.ticket-already {
  font-size: 12px;
  margin-top: 20px;
  padding-bottom: 20px;
  .percent {
    color: #818086;
  }
}
.detail {
  font-size: 12px;
  box-sizing: border-box;
  padding: 15px;
  background-color: #f9f9f9;
  .oneline {
    display: flex;
    justify-content: space-between;
  }
  .twoline {
    margin-top: 5px;
  }
  .title {
    font-size: 12px;
    font-weight: bold;
  }
  .value {
    color: #818086;
  }
  .info-title {
    font-weight: bold;
    padding-bottom: 10px;
  }
}
</style>
