<template>
  <div class="wrap">
    <div class="header">
      <svg class="bnb-icon" aria-hidden="true">
        <use xlink:href="#icon-a-zu206"></use>
      </svg>
      <div class="content">
        <div class="title">MEB DAO治理</div>
        <div class="des">
          DAO 是MEB去中心化的重要一步，快来为MEB未來生态发展建言献策
        </div>
        <div class="wait-get-title">待提取凭证</div>
        <div class="num">15</div>
        <div class="condidate-button">提取</div>
        <div class="key-value">
          <div class="key">质押的xMEB数量:</div>
          <div class="value">312312421</div>
        </div>
        <div class="key-value">
          <div class="key">赎回中的xMEB:</div>
          <div class="value">312312421</div>
        </div>
      </div>
    </div>
    <div class="my-vote">
      <div class="main-tab">
        <div
          class="main-tab-item"
          v-for="item in mainTabList"
          :key="item.key"
          @click="handleChangeMainTab(item.key)"
          :class="{ 'tab-active': mainTabActive === item.key }"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="sub-tab">
        <div
          class="sub-tab-item"
          @click="handleChangeSubTab(1)"
          :class="{ 'tab-active': subTabActive === 1 }"
        >
          进行中
        </div>
        |
        <div
          class="sub-tab-item"
          @click="handleChangeSubTab(2)"
          :class="{ 'tab-active': subTabActive === 2 }"
        >
          已失效
        </div>
      </div>
      <VoteItem
        v-for="item in 2"
        :key="item"
        :subTabActive="subTabActive"
        :mainTabActive="mainTabActive"
        @showRedeem="handleShowRedeem"
        @showStake="handleShowStake"
      ></VoteItem>
      <RedeemPop v-if="redeemShow" @close="handleShowRedeem"></RedeemPop>
      <StakePop v-if="stakeShow" @close="handleShowStake"></StakePop>
    </div>
  </div>
</template>

<script>
import VoteItem from './components/VoteItem.vue'
import RedeemPop from './components/RedeemPop.vue'
import StakePop from './components/StakePop.vue'
export default {
  name: 'My',
  data () {
    return {
      redeemShow: false,
      stakeShow: false,
      mainTabList: [
        {
          name: '我投票的理事',
          key: 1
        },
        {
          name: '我是理事',
          key: 2
        }
      ],
      mainTabActive: 1,
      subTabActive: 1 // 1:进行中 2:已失效
    }
  },
  methods: {
    handleChangeMainTab (key) {
      this.mainTabActive = key
    },
    handleChangeSubTab (key) {
      this.subTabActive = key
    },
    handleShowRedeem () {
      this.redeemShow = !this.redeemShow
    },
    handleShowStake () {
      this.stakeShow = !this.stakeShow
    }
  },
  mounted () {
  },
  components: { VoteItem, RedeemPop, StakePop }
}
</script>

<style scoped lang="scss">
.wrap {
  padding: 20px;
  margin-bottom: 50px;
  .header {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    .bnb-icon {
      width: 110px;
      height: 30px;
    }
    .content {
      text-align: center;
      .title {
        padding-top: 15px;
        font-size: 24px;
        font-weight: bold;
        color: #000000;
      }
      .des {
        font-size: 12px;
        padding: 35px 0;
        color: #818086;
        border-bottom: 1px solid #e3e3e3;
      }
      .wait-get-title {
        padding-top: 20px;
        font-size: 15px;
      }
      .num {
        font-size: 36px;
        padding: 10px 0;
      }
      .condidate-button {
        font-size: 15px;
        // margin: 10px 0;
        color: #fff;
        font-weight: bold;
        line-height: 40px;
        height: 40px;
        background: #000;
        border-radius: 10px;
      }
      .key-value {
        padding-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        .value {
          font-weight: bold;
        }
      }
    }
  }
  .my-vote {
    margin-bottom: 30px;
    margin-top: 10px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    .main-tab {
      display: flex;
      font-size: 17px;
      .main-tab-item {
        margin-right: 20px;
        color: #a2a2a2;
      }
      .tab-active {
        font-weight: bold;
        color: #000;
      }
    }
    .sub-tab {
      padding-top: 20px;
      color: #a2a2a2;
      font-size: 17px;
      display: flex;
      justify-content: space-around;
      .tab-active {
        font-weight: normal;
        color: #000;
      }
    }
  }
}
</style>
